<template>
  <div :class="lang == 'ug' ? 'ug' : 'zh'" >
    <a-spin :spinning="loading">
      <div class="flex alcenter">
        <img
          v-if="account.face != null"
          style="width: 60px; height: 60px; border-radius: 30px"
          :src="account.face"
        />
        <img
          v-else
          style="width: 60px; height: 60px; border-radius: 30px"
          src="../assets/image/woyaoyou_head_defult@2x.png"
        />
        <div class=" flex alcenter" :class="lang == 'ug' ? 'mr15' : 'ml15'" >
          <div class="ft14 cl-main ftw600">{{ account.username }}</div>
          <a-button @click="showPwd = true" type="link">{{lang == 'ug' ? 'مەخپىي نومۇر ئۆزگەرتىش' :  '修改密码'}}</a-button>
          <!-- <div class="mt8 ft12 cl-notice">{{showT}}</div> -->
        </div>
      </div>
      <div class="mt40 flex alcenter">
        <span class="ft20 ftw600 cl-main">{{lang == 'ug' ? 'دۇكىنىم' :  '我的商铺'}}({{ mendians.length }})</span>
        <span class="ft14 cl-notice " :class="lang == 'ug' ? 'mr10' : 'ml10'" 
          >{{lang == 'ug' ? 'ھەر بىر دۇكان مۇستەقىل باشقۇرۇلىدۇ، ئەزالار ئايرىۋېتىلگەن. ھەر بىر دۇكاننى شېرىكلىرىڭىز بىلەن بىللە باشقۇرالايسىز!' :  '每个商铺为独立运营的，会员是不通用的。每个商铺可以邀请合伙人一起管理'}}</span
        >
      </div>
      <div class="mt30 mendian-list">
        <div
          v-for="item in mendians"
          @click="manageAct(item)"
          :class="{ dis: item.shop == null }"
          class="mendian-item flex space"
        >
          <div>
            <div class="flex alcenter">
              <div class="ft16 ftw600 cl-main text-over4">
                {{ item.shop == null ? (lang == 'ug' ? 'دۇكان بىكار قىلىنغان' :"该门店已注销") : item.shop.name }}
              </div>
              <div class="ft14 ftw400 cl-notice text-over4" :class="lang == 'ug' ? 'mr5' : 'ml5'" >
                ({{
                  item.shop == null
                    ? ""
                    : item.shop.application == null
                    ? ""
                    : item.shop.application.name
                }})
              </div>
            </div>
            <div class="mt12 ft12 cl-info flex">
              <div :class="lang == 'ug' ? 'ml10' : 'mr10'" >{{lang == 'ug' ? 'ئېچىلغان ۋاقتى: ' :  '开通时间：'}} </div><div style="direction: ltr;"><span style="direction: ltr;">{{ item.add_time_format }}</span></div>
              
            </div>
          </div>
          <div class="tag" v-if="item.role_id == 1">{{lang == 'ug' ? 'قۇرغۇچى' :  '创始人'}}</div>
          <div class="tag" v-if="item.role_id == 2">{{lang == 'ug' ? 'شېرىك' :  '合伙人'}}</div>
        </div>

        <div @click="addMendian" class="mendian-item add flex alcenter center">
          <i class="iconfont iconadd_big cl-theme"></i>
          <span class="ft16 cl-theme ftw600 " :class="lang == 'ug' ? 'mr10' : 'ml10'" >{{lang == 'ug' ? 'دۇكان قۇرۇش' :  '创建商铺'}}</span>
        </div>
      </div>
    </a-spin>

    <a-modal v-model="showPwd" width="500px" :wrapClassName="lang == 'ug' ? 'ug' :'zh'" :title="lang == 'ug' ? 'مەخپىي نومۇر بەلگىلەش' :'设置密码'" on-ok="handleOk">
      <template slot="footer">
        <a-button key="back" @click="handlePwdCancel"> {{lang == 'ug' ? 'بىكار قىلىش' :  '取消'}} </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="pwdloading"
          @click="handlePwdOk"
        >
        {{lang == 'ug' ? 'جەزىملەش' :  '确认'}}
        </a-button>
      </template>
      <a-form-model
        :model="pwd"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item :label="lang == 'ug' ? 'مەخپىي نومۇر' :'输入密码'">
          <a-input type="password" v-model="pwd.pwd" />
        </a-form-model-item>
        <a-form-model-item :label="lang == 'ug' ? 'يەنە بىر قېتىم' :'再次确认'">
          <a-input type="password" v-model="pwd.repwd" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: localStorage.getItem('lang'),
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      loading: false,
      showPwd: false,
      pwd: {
        pwd: "",
        repwd: "",
      },
      pwdloading: false,
      account: {
        mobile: "",
        username: "",
        face: null,
      },
      mendians: [],
    };
  },

  created() {
    this.getLists();
  },
  methods: {
    handlePwdCancel() {
      this.showPwd = false;
    },
    handlePwdOk() {
      if (this.pwdloading == true) return;
      this.pwdloading = true;
      this.$http
        .api("platform/account/editPwd", {
          pwd: this.pwd.pwd,
          repwd: this.pwd.repwd,
        })
        .then((res) => {
          this.pwdloading = false;
          this.$message.success("密码设置成功");
          this.showPwd = false;
        })
        .catch((res) => {
          this.pwdloading = false;
        });
    },
    getLists() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/account/home")
        .then((res) => {
          this.account = res.account;
          this.mendians = res.mendian;
          if (this.account.password == null || this.account.password == "") {
            this.showPwd = true;
          }
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    manageAct(item) {
      if (item.shop == null) {
        this.$message.error("该门店已注销");
        return false;
      }
      let base_url = '';
      console.log(process.env.NODE_ENV)
      if (process.env.NODE_ENV === 'production') {
        base_url = item.shop.application.production_address;
      }else{
        base_url = item.shop.application.development_address;
      }
      
      //
      window.open(
        base_url + "auth?lang="+this.lang+"&shop_token=" + encodeURIComponent(item.shop_token)
        //, '_self'
      );
    },
    handleOk() {
      this.showForm = false;
    },
    handleCancel() {
      this.showForm = false;
    },
    addMendian() {
      // this.showForm = true;
      this.$router.push("/mendian/create");
    },
  },
};
</script>

<style>
.mendian-list {
  display: grid;
  grid-template-columns: repeat(3, 380px);
  grid-template-rows: repeat(3, 100px);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}
.mendian-item {
  width: 380px;
  height: 100px;
  background: #ffffff;
  border-radius: 8px;
  border-left: 8px solid #ebedf5;
  padding: 24px 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.mendian-item.dis {
  opacity: 0.5;
}
.mendian-item .tag {
  width: 52px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #4772ff;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
  color: #4772ff;
}
.mendian-item:hover {
  transform: translateY(-10px);
}
.mendian-item.add {
  border: 1px dashed #4772ff;
}
</style>